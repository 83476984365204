import React from "react";
import PropTypes from "prop-types";
import TopBar from "../TopBar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink} from 'react-router-dom';
import Link from "@material-ui/core/Link";

const Footer = ({className}) => {

    const timeConverter = (UNIX_timestamp) =>{
        const a = new Date(UNIX_timestamp * 1000);
        const year = a.getFullYear();
        const month = a.getMonth();
        const date = a.getDate();
        return `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}`;
    };

    return (
        <div className={className} style={{width: "100%"}}>
            <Grid container
                  justify={"space-between"}
                  alignItems={"flex-end"}>
                <Typography variant={"body2"} style={{color: "#ffffff"}}>
                    {new Date().getFullYear()} &copy; Copyright SnapShot Postcard, LLC. All rights reserved.
                </Typography>
                <Typography
                    variant={"body2"}
                    style={{color: "#ffffff"}}
                >
                    <Link
                        to={"/agreement"}
                        component={RouterLink}
                        style={{color: "#ffffff"}}
                    >
                        User Agreement
                    </Link> | <Link
                        to={"/privacy"}
                        component={RouterLink}
                        style={{color: "#ffffff"}}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            </Grid>
        </div>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
};

export default Footer;
