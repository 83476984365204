import React from 'react';

const GoogleMap = ({address1, city, state, width, height}) => {
    return (
        <React.Fragment>
            <iframe style={{"height": height, "width": width}}
                    title={"Google Map"}
                    src={`https://maps.google.com/maps?q=${address1 ? address1 + ", " : ""}${city ? city + ", " : ""}${state ? state + ", " : ""}&t=&z=14&ie=UTF8&iwloc=&output=embed`}/>
        </React.Fragment>
    );
};

export default GoogleMap;
