import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  image: {
    cursor: 'pointer',
  },
  dialogImage: {
    width: 500,
  },
});

const ImageModal = ({images, thumb, preview}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleOpen() {
    if (images.length > 0)
      setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <img src={images[0] ? `${thumb}${images[0]}` : `https://placehold.it/125x95`}
           className={images[0] ? classes.image : ''}
           alt={"Thumbnail"}
           onClick={handleOpen}/>
      <SimpleDialog images={images}
                    preview={preview}
                    open={open}
                    onClose={handleClose}/>
    </>
  );
};

const SimpleDialog = (props) => {
  const classes = useStyles();
  const {onClose, images, open, preview} = props;

  function handleClose() {
    onClose(images);
  }

  return (
    <Dialog onClose={handleClose}
            className={classes.dialog}
            maxWidth={false}
            aria-labelledby="simple-dialog-title"
            open={open}>
      {
        images.map((image, index) => (
          <img key={index}
               className={classes.dialogImage}
               alt="Dialog"
               src={`${preview}${image}`}/>
        ))
      }
    </Dialog>
  );
};

export default ImageModal;
