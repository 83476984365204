/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';

import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import PresentationView from './views/Presentation';

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/home"/>
    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('views/Error401'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('views/Error404'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('views/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404"/>
            }
        ]
    },
    {
        route: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/login',
                exact: true,
                component: lazy(() => import('views/Login'))
            },
            {
                path: '/home',
                exact: true,
                component: PresentationView
            },
            {
                path: '/orders',
                exact: true,
                component: lazy(() => import('views/OrderList'))
            },
            {
                path: '/orders/:id',
                exact: true,
                component: lazy(() => import('views/OrderDetails'))
            },
            {
                path: '/transactions',
                exact: true,
                component: lazy(() => import('views/TransactionList'))
            },
            {
                path: '/transactions/:id',
                exact: true,
                component: lazy(() => import('views/TransactionDetails'))
            },
            {
                path: '/accounts',
                exact: true,
                component: lazy(() => import('views/AccountList'))
            },
            {
                path: '/accounts/:id',
                exact: true,
                component: lazy(() => import('views/AccountDetails'))
            },
            {
                path: '/myAccount',
                exact: true,
                component: lazy(() => import('views/MyAccount'))
            },
            {
                path: '/myAccount/:tab',
                exact: true,
                component: lazy(() => import('views/MyAccount'))
            },
            {
                path: '/myAccount/orders/:id',
                exact: true,
                component: lazy(() => import('views/MyOrderDetails'))
            },
            {
                path: '/myAccount/transactions/:id',
                exact: true,
                component: lazy(() => import('views/MyTransactionDetails'))
            },
            {
                path: '/organizations',
                exact: true,
                component: lazy(() => import('views/Papa'))
            },
            {
                path: '/batches',
                exact: true,
                component: lazy(() => import('views/BatchList'))
            },
            {
                path: '/purchase',
                exact: true,
                component: lazy(() => import('views/BuyCredits'))
            },
            {
                path: '/privacy',
                exact: true,
                component: lazy(() => import('views/PrivacyPolicy'))
            },
            {
                path: '/agreement',
                exact: true,
                component: lazy(() => import('views/UserAgreement'))
            },
            {
                path: '/support',
                exact: true,
                component: lazy(() => import('views/Support'))
            },
            {
                component: () => <Redirect to="/errors/error-404"/>
            }
        ]
    }
];

export default routes;
