export const pressData = [
    {
        image: "/images/wired.gif",
        author: "Wired Staff",
        text: "\"We consider this to be the spiritual successor to the Polaroid, especially as Polaroid itself has done such a bad job of things.\"",
        link: "http://www.wired.com/gadgetlab/2009/04/send-real-card/",
    },
    {
        image: "/images/boston.png",
        author: "boston.com",
        text: "\"Electronic communications are great, but what is a vacation without postcards? SnapShot Postcard promised to convert my iPhone photos to printed postcards — complete with personal message — and even mail them. ...The card I sent \"to us from us\" came out beautifully, and is now on the fridge.\"",
        link: "http://www.boston.com/travel/articles/2010/12/05/in_the_wilderness_and_wired_for_convenience/?page=1",
    },
    {
        image: "/images/budgettravel.png",
        author: "Budget Travel",
        text: "\"The Gateway of India is about as emblematic of Mumbai as the Eiffel Tower is of Paris, so I took an iPhone photo and opened SNAPSHOT POSTCARD. Selecting the image I'd just taken, I entered an address and a short message, and the company handled the rest, printing out and mailing a custom postcard to my wife (it arrived in four days).\"",
        link: "https://www.budgettravel.com/article/travel-by-smartphone-mumbai-india_5787",
    },
    {
        image: "/images/vegetarians.png",
        author: "The Vegetarians Abroad",
        text: "\"Very quick, very handy. You take your picture, type in your message and address, and they do the stamping and sending. I shot a pic of the boys sledding out in the wilds of Illinois and sent it without even going in the house. Can't beat that.\"",
        link: "The Vegetarians Abroad",
    },
    {
        image: "/images/sfgate.png",
        author: "SFGate",
        text: "\"New iPhone App Gives Holiday Cards High-Tech Boost!\"",
        link: null,
    },
    {
        image: "/images/brandice.png",
        author: "Brandice Says",
        text: "\"Great service at SnapShot Postcard... Now, THAT is how you keep customers even when you're having issues with your product. Quick responses, make it right, and now I know exactly which of the several postcards apps I'll actually be using.\"",
        link: null,
    },
    {
        image: "/images/idahopresstribune.png",
        author: "Idaho Press Tribune",
        text: "\"Local innovators are working on projects here to improve the lives of others — whether that be providing a computer foot-control to those with limited hand movements, training bomb squads to neutralize explosive devices, or helping loved ones reconnect over a postcard.\"",
        link: "https://www.idahopress.com/members/innovation-brews-in-nampa/article_c2462d9c-5959-11e2-85c2-0019bb2963f4.html?mode=story",
    },
];
