import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Typography, Grid} from '@material-ui/core';
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import {pressData} from "./pressData"
import Hidden from "../Header/Header";
import Card from "@material-ui/core/Card";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default
    },
    inner: {
        padding: theme.spacing(6, 3),
        width: theme.breakpoints.values.lg,
        maxWidth: '100%',
        margin: '0 auto'
    },
    media: {
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    logo: {
        width: 150,
        padding: theme.spacing(2),
    },
    pressBody: {
        marginLeft: "4%",
        [theme.breakpoints.down('md')]: {
            margin: 0,
            padding: "10px 20px",
        }
    },
    buttons: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    buttonContainer: {
        margin: "2% 10%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            margin: "2%",
        }
    },
    buttonImage: {
        width: 200,
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    pressContainer: {
        minHeight: 200,
        padding: 24,
        textAlign: "center",
        width: "60%",
        margin: "0 auto",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        }
    },
}));

const UserFlows = ({className, ...rest}) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);

    const handleStepChange = step => {
        setActiveStep(step);
    };


    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.inner}>
                <Grid
                    alignItems="center"
                    container
                    justify="space-between"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            gutterBottom
                            variant="h2"
                            style={{textAlign: "center", marginBottom: 50}}
                        >
                            See what others are saying about us
                        </Typography>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            interval={5000}
                        >
                            {pressData.map((item, index) => (
                                <div key={index}>
                                    <Card className={classes.pressContainer}>
                                        <Grid container
                                              justify={"center"}
                                              alignItems={"center"}
                                              direction={"column"}>
                                            <img src={item.image}
                                                 className={classes.logo}
                                                 alt={`${item.author} logo`}/>
                                            <Typography variant={"h5"} style={{lineHeight: 1.5}}>
                                                {item.text}
                                            </Typography>
                                            <Typography variant={"subtitle1"}>
                                                <br/>
                                                <span style={{fontWeight: "bold"}}>{item.author}</span>
                                                {item.link !== null ?
                                                    <span> | <a href={item.link}
                                                                target={"_blank"}>Read the whole article →</a></span>
                                                    : null}
                                            </Typography>
                                        </Grid>
                                    </Card>
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                    </Grid>
                </Grid>
                <div className={classes.buttons}>
                    <div className={classes.buttonContainer}>
                        <Button
                            component="a"
                            href="http://www.snapshotpostcard.com/AppStore"
                            target="_blank"
                            variant="contained"
                            style={{
                                padding: 0,
                                borderRadius: 10,
                            }}
                        >
                            <img
                                className={classes.buttonImage}
                                src="/images/appleDownload.png"
                                alt="App Store download"
                            />
                        </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button
                            component="a"
                            href="http://www.snapshotpostcard.com/Android"
                            target="_blank"
                            variant="contained"
                            style={{
                                padding: 0,
                                margin: 0,
                                borderRadius: 10,
                                backgroundColor: "#282828"
                            }}
                        >
                            <img
                                className={classes.buttonImage}
                                src="/images/androidDownload.png"
                                alt="Android download"
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserFlows.propTypes = {
    className: PropTypes.string
};

export default UserFlows;
