/** JWT.js **/
export const decodeToken = (token) => {
    let isJwt = true, claim;

    try {
        const parts = token.split(".");
        claim = jwtDecodePart(parts[1]);
    } catch (e) {
        isJwt = false;
    }

    return {
        claim,
        get isJwt() {
            return isJwt
        },
        get isSigned() {
            return false
        },
        get isValid() {
            return isJwt
        },
    }
};

function jwtDecodePart(str) {
    const json = atob(str);
    return JSON.parse(json);
}
