/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import WorkIcon from "@material-ui/icons/WorkOutline";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import PersonIcon from "@material-ui/icons/PersonOutline";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import CollectionsIcon from "@material-ui/icons/CollectionsOutlined";


import {Label} from 'components';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Home',
        href: '/',
        icon: HomeIcon
      },
        {
            title: 'Support',
            href: '/support',
            icon: HomeIcon
        },
    ],
  }
];
